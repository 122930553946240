import { z } from "zod";

import { camelCaseKeys, snakeCase, snakeCaseKeys } from "~/utils/casing";
import { getUserTimeZone } from "~/utils/time";

import { ErrorSchema, ErrorT, UserTokenSchema } from "./api-schemas";
import { forgetUserToken, getUserToken, setUserToken } from "./session";

export const http: Http = {
  get: (config) => request({ method: "GET", ...config }),
  post: (config) => request({ method: "POST", ...config }),
  put: (config) => request({ method: "PUT", ...config }),
  patch: (config) => request({ method: "PATCH", ...config }),
  delete: (config) => request({ method: "DELETE", ...config, schema: false }),
  registerTenantId: (tenantId) => {
    _registeredTenantId = tenantId;
  },
};

const ErrorResponseSchema = z.object({ errors: z.array(ErrorSchema) });

let tokenRefresh: PromiseWithResolvers<boolean> | null = null;

let _registeredTenantId: string | null = null;

async function request<Schema extends z.ZodTypeAny | false>(
  httpRequestConfig: HttpRequestConfig<Schema>,
): Promise<HttpResponse<Schema extends false ? void : z.infer<Exclude<Schema, boolean>>>> {
  // Await possible pending token refresh before making request
  await tokenRefresh?.promise;

  const httpRequest = createHttpRequest(httpRequestConfig);

  const { url, ...requestInit } = httpRequest;
  const response = await fetch(url, requestInit);

  const httpResponse = await createHttpResponse(httpRequestConfig, httpRequest, response);

  if (response.ok && (httpResponse.data !== undefined || httpRequestConfig.schema === false)) {
    return httpResponse;
  }

  if (response.status === 401 && httpRequestConfig.no401Retry !== true) {
    if (!tokenRefresh) {
      tokenRefresh = Promise.withResolvers<boolean>();
      // Refresh token and try this request again
      try {
        await requestNewToken();
        tokenRefresh?.resolve(true);
        return request({ ...httpRequestConfig, no401Retry: true });
      } catch {
        forgetUserToken();
        if (location.pathname !== "/login") location.href = "/login";
        tokenRefresh?.resolve(false);
        throw httpResponse;
      } finally {
        tokenRefresh = null;
      }
    } else {
      // Await the pending token refresh and try this request again
      try {
        const success = await tokenRefresh.promise;
        if (!success) throw null;
        return request({ ...httpRequestConfig, no401Retry: true });
      } catch {
        throw httpResponse;
      }
    }
  }

  throw httpResponse;
}

function createHttpRequest<Schema extends z.ZodTypeAny | false>(httpRequestConfig: HttpRequestConfig<Schema>) {
  const { path, method, params, body, signal, noTenantId } = httpRequestConfig;

  const httpRequest: HttpRequest = {
    url: new URL(normalizeApiPath(path), location.origin),
    method,
    headers: new Headers(),
    body: undefined,
    signal,
  };

  const searchParams = new URLSearchParams();
  for (const [key, value] of new URLSearchParams(params)) {
    searchParams.append(snakeCase(key), value);
  }

  httpRequest.url.search = searchParams.toString();

  const accessToken = getUserToken()?.access;
  if (accessToken) {
    httpRequest.headers.set("Authorization", `Bearer ${accessToken}`);
  }

  if (!noTenantId && _registeredTenantId) {
    httpRequest.headers.set("X-Tenant-Id", _registeredTenantId);
  }

  httpRequest.headers.set("Accept", "application/json");
  httpRequest.headers.set("X-User-Timezone", getUserTimeZone());

  if (body !== undefined) {
    if (body instanceof FormData) {
      if (import.meta.env.DEV) {
        console.warn(
          `Using FormData for request body in ${httpRequest.method} ${httpRequest.url}\n` +
            "Make sure you decamelized the keys as this is not done automatically.",
        );
      }
      httpRequest.body = body;
    } else {
      httpRequest.headers.set("Content-Type", "application/json");
      httpRequest.body = JSON.stringify(snakeCaseKeys(body));
    }
  }

  return httpRequest;
}

async function createHttpResponse<Schema extends z.ZodTypeAny | false>(
  httpRequestConfig: HttpRequestConfig<Schema>,
  httpRequest: HttpRequest,
  response: Response,
) {
  const { data, errors } = await parseResponseData(httpRequestConfig, httpRequest, response);

  return new HttpResponse(httpRequest, response.status, response.headers, data, errors);
}

async function parseResponseData<Schema extends z.ZodTypeAny | false>(
  httpRequestConfig: HttpRequestConfig<Schema>,
  httpRequest: HttpRequest,
  response: Response,
) {
  const { schema } = httpRequestConfig;

  const isJsonResponse = response.headers.get("Content-Type")?.includes("application/json");

  const json: unknown = isJsonResponse ? camelCaseKeys(await response.json()) : {};

  let data: unknown = undefined;
  if (isJsonResponse && schema !== false && response.ok) {
    const dataResult = schema.safeParse(json);
    if (!dataResult.success) {
      throw new HttpResponseSchemaMismatchError(httpRequest, json, dataResult.error.format());
    }
    data = dataResult.data;
  }

  const errorsResult = ErrorResponseSchema.safeParse(json);
  const errors = errorsResult.success ? errorsResult.data.errors : [];

  return { data, errors } as {
    data: Schema extends false ? undefined : z.infer<Exclude<Schema, boolean>>;
    errors: ErrorT[];
  };
}

async function requestNewToken() {
  try {
    const refreshToken = getUserToken()?.refresh;
    if (!refreshToken) throw null;

    const url = new URL(normalizeApiPath("auth/refresh"), location.origin);
    const headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");
    headers.set("X-Refresh-Token", `Bearer ${refreshToken}`);

    const response = await fetch(url, { method: "POST", headers });
    if (!response.ok) throw null;

    const json = camelCaseKeys(await response.json()) as unknown;
    const token = UserTokenSchema.parse(json);
    setUserToken(token, true);
  } catch {
    console.error(new Error("HttpError: Failed to refresh token"));
    throw null;
  }
}

function normalizeApiPath(path: string) {
  if (!path.startsWith("/")) path = `/${path}`;
  if (!path.startsWith("/api/")) path = `/api${path}`;
  return path;
}

export class HttpResponse<Data = unknown> {
  constructor(
    public request: HttpRequest,
    public status: number,
    public headers: Headers,
    public data: Data,
    public errors: ErrorT[],
  ) {}
}

export class HttpResponseSchemaMismatchError extends Error {
  name = "HttpResponseSchemaMismatchError";

  constructor(
    public httpRequest: HttpRequest,
    public responseData: unknown,
    public schemaErrors: z.ZodFormattedError<unknown>,
  ) {
    super("Response did not match the expected schema");
  }
}

export function isHttpResponse(response: unknown): response is HttpResponse {
  return response instanceof HttpResponse;
}

interface Http {
  get: Get;
  post: Post;
  put: Post;
  patch: Post;
  delete: Delete;
  registerTenantId: (tenantId: string | null) => void;
}

type Get = <Schema extends z.ZodTypeAny | false>(
  config: Omit<HttpRequestConfig<Schema>, "method" | "body">,
) => Promise<HttpResponse<Schema extends false ? void : z.infer<Exclude<Schema, boolean>>>>;

type Post = <Schema extends z.ZodTypeAny | false>(
  config: Omit<HttpRequestConfig<Schema>, "method">,
) => Promise<HttpResponse<Schema extends false ? void : z.infer<Exclude<Schema, boolean>>>>;

type Delete = (config: Omit<HttpRequestConfig<false>, "method" | "schema">) => Promise<HttpResponse<void>>;

interface HttpRequestConfig<Schema extends z.ZodTypeAny | false> {
  method: Uppercase<keyof Http>;
  path: string;
  params?: ConstructorParameters<typeof URLSearchParams>[0] | undefined;
  body?: FormData | Record<string, unknown> | undefined;
  schema: Schema;
  signal?: AbortSignal | undefined;
  no401Retry?: boolean | undefined;
  noTenantId?: boolean | undefined;
}

interface HttpRequest {
  url: URL;
  method: Uppercase<keyof Http>;
  headers: Headers;
  body: FormData | string | undefined;
  signal: AbortSignal | undefined;
}
