import { redirect } from "@remix-run/react";

import { UserTokenSchema } from "./api-schemas";
import { CURRENT_USER_TOKEN } from "./storage";

export async function requireUser() {
  const currentUserToken = getUserToken();
  if (!currentUserToken?.access) throw redirect("/login");

  return currentUserToken;
}

export async function forbidUser() {
  const currentUserToken = getUserToken();
  if (currentUserToken?.access) throw redirect("/");

  return currentUserToken;
}

export function getUserToken() {
  const userToken = localStorage.getItem(CURRENT_USER_TOKEN);
  if (!userToken) return null;

  try {
    return UserTokenSchema.parse(JSON.parse(userToken));
  } catch {
    localStorage.removeItem(CURRENT_USER_TOKEN);
    return null;
  }
}

export function setUserToken(token: any, merge = false) {
  const data = merge ? { ...getUserToken(), ...token } : token;
  localStorage.setItem(CURRENT_USER_TOKEN, JSON.stringify(data));
}

export function forgetUserToken() {
  localStorage.removeItem(CURRENT_USER_TOKEN);
}
