const toString = Object.prototype.toString;

export function isNumber(arg: any): arg is number {
  return typeof arg === "number";
}

export function isObject(arg: any): arg is Record<string, any> {
  return arg === Object(arg);
}

export function isIterableObject(arg: any): arg is object {
  return isObject(arg) && !isArray(arg) && !isDate(arg) && !isRegExp(arg) && !isBoolean(arg) && !isFunction(arg);
}

export function isArray(arg: any): arg is any[] {
  return toString.call(arg) === "[object Array]";
}

export function isDate(arg: any): arg is Date {
  return toString.call(arg) === "[object Date]";
}

export function isRegExp(arg: any): arg is RegExp {
  return toString.call(arg) === "[object RegExp]";
}

export function isBoolean(arg: any): arg is boolean {
  return toString.call(arg) === "[object Boolean]";
}

export function isFunction(arg: any): arg is (args: any) => any {
  return typeof arg === "function";
}

export function isString(arg: any): arg is string {
  return typeof arg === "string";
}
