import { z } from "zod";

export const DateTimeSchema = z
  .string()
  .datetime({ offset: true })
  .transform((val) => new Date(val));

export type Device = z.infer<typeof DeviceSchema>;
export const DeviceSchema = z.object({
  id: z.string(),
  discardedAt: z.string().nullable(),
  hardwareVersion: z.string().nullable(),
  location: z.string().nullable(),
  serialNumber: z.string(),
  softwareVersion: z.string().nullable(),
  status: z.string(),
  timezone: z.string().nullable(),
  tenantId: z.string().nullable(),
  name: z.string().nullable(),
  contactPerson: z.string().nullable(),
  createdAt: DateTimeSchema,
  updatedAt: DateTimeSchema,
  lastSeen: z.string().nullable(),
  deviceModel: z.object({ id: z.string(), name: z.string() }).nullable(),
});

export type DeviceEvent = z.infer<typeof DeviceEventSchema>;
export const DeviceEventSchema = z.object({
  id: z.string(),
  manifestedAt: z.string(),
  eventType: z.string().nullable(),
  eventGroup: z.string().nullable(),
  eventOperation: z.string().nullable(),
  device: DeviceSchema,
});

export type User = z.infer<typeof UserSchema>;
export const UserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  username: z.string(),
  email: z.string(),
  locale: z.string(),
  tenantId: z.string().nullable(),
  role: z.enum(["administrator", "technician"]),
  createdAt: DateTimeSchema,
  updatedAt: DateTimeSchema,
  deletedAt: DateTimeSchema.nullable(),
  notificationDevices: z.array(DeviceSchema).optional(),
  doNotDisturb: z.string().optional(),
  enableNotifications: z.boolean(),
});

export type UserToken = z.infer<typeof UserTokenSchema>;
export const UserTokenSchema = z.object({
  access: z.string(),
  refresh: z.string().optional(),
});

export type Credentials = z.infer<typeof CredentialsSchema>;
export const CredentialsSchema = z.object({
  username: z.string(),
  password: z.string(),
});

export type Locale = z.infer<typeof LocaleSchema>;
export const LocaleSchema = z.object({
  key: z.string(),
  displayName: z.string(),
  localeDisplayName: z.string(),
});

export type PaginationMeta = z.infer<typeof PaginationMetaSchema>;
export const PaginationMetaSchema = z.object({
  currentPage: z.number(),
  pageSize: z.number(),
  totalCount: z.number(),
  totalPages: z.number(),
});

// Use ErrorT instead of Error to avoid conflicts with the built-in Error type
export type ErrorT = z.infer<typeof ErrorSchema>;
export const ErrorSchema = z.object({
  title: z.string(),
  detail: z.string(),
  errorKey: z.string().nullable(),
  source: z.string().nullable(),
});

const DeviceModelSensorSchema = z.object({
  id: z.string().uuid(),
  defaultSystemMax: z.number().nullable().optional(),
  defaultSystemMin: z.number().nullable().optional(),
  defaultTenantMax: z.number().nullable().optional(),
  defaultTenantMin: z.number().nullable().optional(),
  displayFormat: z.string().nullable(),
  name: z.string().nullable(),
  sensorName: z.string().nullable(),
  sensorType: z.string().nullable(),
  systemAlarm: z.boolean().optional(),
  tenantAlarm: z.boolean().optional(),
  type: z.string().nullable(),
  unit: z.string().nullable(),
});

export type DeviceSensor = z.infer<typeof DeviceSensorSchema>;
export const DeviceSensorSchema = z.object({
  id: z.string().uuid(),
  sensorName: z.string(),
  type: z.string().nullable(),
  tenantMin: z.number().nullable(),
  tenantMax: z.number().nullable(),
  tenantAlarm: z.boolean(),
  systemMin: z.number().nullable().optional(),
  systemMax: z.number().nullable().optional(),
  systemAlarm: z.boolean().optional(),
  modelSensor: DeviceModelSensorSchema.optional(),
  device: DeviceSchema.optional(),
});

export type Tenant = z.infer<typeof TenantSchema>;
export const TenantSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: z.string().nullable(),
  contactPerson: z.string().nullable(),
  country: z.string().nullable(),
  locale: z.string(),
  contactPhoneNumber: z.string().nullable(),
  timezone: z.string().nullable(),
  deletedAt: DateTimeSchema.nullable(),
  createdAt: DateTimeSchema,
  updatedAt: DateTimeSchema,
});

export type DeviceSensorAlarm = z.infer<typeof DeviceSensorAlarmsSchema>;
export const DeviceSensorAlarmsSchema = z.object({
  id: z.string(),
  alarmType: z.string(),
  manifestedAt: z.string(),
  boundType: z.string(),
  resolvedAt: z.string().nullable(),
  sensor: DeviceSensorSchema,
  device: DeviceSchema,
  tenant: TenantSchema,
});

export type DeviceDocument = z.infer<typeof DocumentSchema>;
export const DocumentSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  name: z.string(),
  note: z.string().nullable().optional(),
  url: z.string().nullable(),
  type: z.string().nullable(),
  visibleToTenant: z.boolean(),
  documentType: z.string(),
  uploadedBy: UserSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  file: z.instanceof(File).optional(),
  state: z.enum(["processing", "completed", "failed"]).nullable().optional(),
});

export type DeviceSensorMetrics = z.infer<typeof DeviceSensorMetricsSchema>;
export const DeviceSensorMetricsSchema = z.object({
  timestamp: DateTimeSchema,
  metrics: z.record(z.string(), z.number()),
});

export type GenerateReportRequest = z.infer<typeof GenerateReportRequestSchema>;
export const GenerateReportRequestSchema = z.object({
  deviceId: z.string(),
  name: z.string(),
  note: z.string().nullable().optional(),
  type: z.string(),
  visibleToTenant: z.boolean(),
  documentType: z.string(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  eventTypes: z.array(z.string()).optional(),
  eventGroups: z.array(z.string()).optional(),
  eventOperations: z.array(z.string()).optional(),
  sensorIds: z.array(z.string()).optional(),
  includeGraphs: z.boolean().optional(),
});

export type DownloadPayloadRequest = z.infer<typeof DownloadPayloadRequestSchema>;
export const DownloadPayloadRequestSchema = z.object({
  deviceId: z.string(),
  startDate: z.string(),
  endDate: z.string(),
});

export type DeviceLatestMetrics = z.infer<typeof DeviceLatestMetricsSchema>;
export const DeviceLatestMetricsSchema = z.object({
  deviceMetric: z.object({
    id: z.string(),
    measuredAt: DateTimeSchema,
    createdAt: DateTimeSchema,
    payload: z.record(z.string(), z.number()).optional(),
  }),
});

export type DeviceEventFilter = z.infer<typeof DeviceEventFilterSchema>;
export const DeviceEventFilterSchema = z.object({
  name: z.string(),
  displayName: z.string(),
});

export type DeviceEventFilters = z.infer<typeof DeviceEventFiltersSchema>;
export const DeviceEventFiltersSchema = z.object({
  eventTypes: z.array(DeviceEventFilterSchema),
  eventGroupTypes: z.array(DeviceEventFilterSchema),
  eventOperations: z.array(DeviceEventFilterSchema),
});
