import moment from "moment-timezone";

/*====================Constants========================*/
export const SECOND_IN_MILLIS = 1_000;
export const MINUTE_IN_MILLIS = 60 * SECOND_IN_MILLIS;
export const HOUR_IN_MILLIS = 60 * MINUTE_IN_MILLIS;
export const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;
export const TIME_RANGE_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])-(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

/*====================Check========================*/
export function isValidTimeRange(timeRange: string) {
  return TIME_RANGE_REGEX.test(timeRange);
}

/*====================Getters========================*/
export function getUserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/*====================Others========================*/
export function timezonesOption() {
  return moment.tz.names().map((tz) => ({
    label: tz,
    value: tz,
  }));
}

export function getDateFromDateTime(dateTime: string | undefined): string {
  if (!dateTime) {
    return "";
  }

  const date = new Date(dateTime);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid dateTime format");
  }

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}/${month}/${day}`;
}

export function getDefaultDateTime(datetime: string | Date | undefined | null) {
  if (!datetime) return "";

  const date = new Date(datetime);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
